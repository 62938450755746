import useYup from "@usereact/use-yup"
import React, { useEffect, useState } from "react"
import { format } from "date-fns"

import * as yup from "yup"

const validationSchema = yup.object().shape({
    page_title: yup.string().required("Field is required"),
    // page_number: yup
    //     .string()
    //     .test("page_number", "Must be greater than 0", (value) => Number(value) > 0),
    page_description: yup.string().min(10),
    page_photo: yup.object().shape({
        file: yup
            .object()
            .shape({
                name: yup.string().required("Filename is required"),
                type: yup
                    .string()
                    .when(
                        "name",
                        (name, schema) =>
                            name &&
                            schema.test(
                                "fileType",
                                "File must be JPG, JPEG or PNG",
                                (value) =>
                                    ["image/jpg", "image/jpeg", "image/png"].includes(
                                        value
                                    )
                            )
                    )
                    .required("Filetype is missing"),
            })
            .label("File"),
    }),
})

const PageForm = (props) => {
    const { editing } = props

    const initialState = {
        page_title: "",
        page_description: "",
    }
    const [fieldValues, setFieldValues] = useState(initialState)

    // const [productPhoto, setProductPhoto] = useState({});
    const [image, setImage] = useState(null)

    const [formError, setFormError] = useState("")

    const { errors, validate } = useYup(fieldValues, validationSchema, {
        validateOnChange: true,
    })

    useEffect(() => {
        // resetForm();
        setFieldValues(props.productInfo ? props.productInfo : initialState)
        // eslint-disable-next-line
    }, [props])

    const handleFieldChange = (e) => {
        const { name, value } = e.target
        // TODO:Add input masking on the telephone numbers

        setFieldValues({
            ...fieldValues,
            [name]: value,
        })
        validate()
    }

    const handlePhotoChange = (e) => {
        const { files } = e.target
        const photoFile = files[0]
        console.log(photoFile)
        if (photoFile) {
            setFieldValues({
                ...fieldValues,
                page_photo: { file: { name: photoFile.name, type: photoFile.type } },
            })
            setImage(photoFile)
            // setImage(URL.createObjectURL(photoFile));
        }
    }

    const resetForm = () => {
        setFormError("")
        setImage()
        setFieldValues(initialState)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        validate()
        // return
        const uploadData = fieldValues

        setFormError("")

        if (Object.keys(errors).length < 1) {
            // console.log(uploadData)
            try {
                // eslint-disable-next-line no-empty
                // props.onAdd(uploadData);

                if (props.editing) {
                    uploadData.dateUpdated = format(new Date(), "yyyy-MM-dd HH:mm:ss")
                    props.onUpdate(uploadData, image)
                } else {
                    uploadData.dateAdded = format(new Date(), "yyyy-MM-dd HH:mm:ss")
                    props.onAdd(uploadData, image)
                }
                resetForm()
            } catch (error) {
                console.error(error)
            }
        } else {
            console.error(errors)
            setFormError("Please fill in all required fields")
        }
    }

    const handleCancel = () => {
        const uploadData = fieldValues
        if (props.editing) {
            props.onRemove(uploadData.id)
        } else {
            props.onCancel()
        }
    }

    return (
        <div className="flex flex-col w-full h-full">
            {formError && (
                <div
                    className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-3 rounded relative"
                    role="alert"
                >
                    <strong className="font-bold">Error!</strong>
                    <span className="block sm:inline"> {formError}</span>
                </div>
            )}
            <div className="w-full text-left">
                {/* <p className="text-sm py-3">
          Please drag the map marker to indicate the dropoff location
        </p> */}

                <form className="w-full" onSubmit={handleSubmit}>
                    <div className="flex flex-1">
                        <div className="w-full py-3 text-center flex flex-col content-center items-center justify-center">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="company-address"
                            >
                                Page Photo
                            </label>
                            {(image || fieldValues.image) && !errors.page_photo && (
                                <img
                                    className="mx-auto block object-center object-cover"
                                    src={
                                        image
                                            ? URL.createObjectURL(image)
                                            : fieldValues.image
                                    }
                                    style={{ height: "300px" }}
                                    alt="Preview of business logo"
                                />
                            )}
                            {/* {values.product_photo && (
                <p className="text-xs">{values.product_photo.file.name}</p>
              )} */}

                            {errors.page_photo &&
                            errors.page_photo.file &&
                            errors.page_photo.file.type ? (
                                <p className="text-red-500 text-xs">
                                    {errors.page_photo.file.type}
                                </p>
                            ) : (
                                <p className="text-red-500 text-xs">
                                    {errors.page_photo?.file.name}
                                </p>
                            )}

                            {/* {errors.page_photo &&
                                errors.page_photo.file &&
                                errors.page_photo.file.name && (
                                    <p className="text-red-500 text-xs">
                                        {errors.page_photo.file.name}
                                    </p>
                                )} */}

                            <label className="w-64 flex flex-col items-center my-2 px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
                                <svg
                                    className="w-8 h-8"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                </svg>
                                <span className="mt-2 text-base leading-normal">
                                    {editing ? "Change" : "Upload"} photo
                                </span>
                                <input
                                    name="page_photo"
                                    type="file"
                                    className="hidden"
                                    onChange={handlePhotoChange}
                                />
                            </label>
                            {errors.product_photo &&
                                errors.product_photo.file &&
                                errors.product_photo.file.name && (
                                    <p className="text-red-500 text-xs">
                                        {errors.product_photo.file.name}
                                    </p>
                                )}
                        </div>

                        <div className="w-full px-3 my-2 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Page title
                            </label>
                            <input
                                type="text"
                                name="page_title"
                                value={fieldValues.page_title}
                                onChange={(e) => handleFieldChange(e)}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                placeholder="Title of page"
                            />
                            {errors.page_title && (
                                <p className="text-red-500 text-xs italic">
                                    {errors.page_title}
                                </p>
                            )}
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Page description
                            </label>
                            <textarea
                                name="page_description"
                                value={fieldValues.page_description}
                                onChange={(e) => handleFieldChange(e)}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                placeholder="Description of page"
                                rows="3"
                            />
                            {errors.page_description && (
                                <p className="text-red-500 text-xs italic">
                                    {errors.page_description}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <button
                            type="button"
                            className="text-red-500 font-bold py-2 px-4 mt-5 rounded"
                            onClick={handleCancel}
                        >
                            {editing ? "Remove" : "Cancel"}
                        </button>

                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PageForm

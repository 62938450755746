import React, { useState, useEffect } from "react"
import { SwatchesPicker } from "react-color"

const ColorPicker = (props) => {
    // console.log(props)
    const { labelText } = props

    useEffect(() => {
        console.log(props.color)
    }, [props.color])

    const [showColorPicker, toggleColorPicker] = useState(false)

    const popover = {
        position: "absolute",
        zIndex: "2",
    }
    const cover = {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    }

    const handleClick = () => {
        toggleColorPicker(!showColorPicker)
    }

    const handleClose = () => {
        toggleColorPicker(false)
    }

    return (
        <button
            type="button"
            className="flex flex-col items-center p-3 border border-gray-400 rounded"
            onClick={handleClick}
        >
            <i
                className="fas fa-palette fa-lg"
                style={{
                    color: `#${props.color}`,
                }}
            />
            {/* <div
                className="h-8 w-8"
                style={{
                    backgroundColor: `#${props.color}`,
                }}
            ></div>
            <span className="pt-1">{labelText}</span> */}
            {showColorPicker && (
                <div style={popover}>
                    <div onClick={handleClose} />
                    <SwatchesPicker onChange={props.onChange} />
                </div>
            )}
        </button>
    )
}

export default ColorPicker

import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"

const PageWrapper = ({ children }) => (
    <div className="flex flex-col w-full h-screen">
        <Navbar />
        <main className="flex-grow h-auto bg-orange-300 pb-16 min-w-full">
            {children}
        </main>
        <Footer />
    </div>
)

export default PageWrapper

// @flow

import React, { useEffect } from "react"

const Ctx = React.createContext()

// Styled Components
// ==============================

const ToastContainer = (props) => (
    <div style={{ position: "fixed", right: 0, top: 0, zIndex: 999 }} {...props} />
)
const Toast = ({ text, status, onDismiss }) => {
    useEffect(() => {
        //Hide toast after 3 seconds
        setTimeout(() => {
            console.log("hide toast")
            onDismiss()
        }, 3000)
    })

    let color = ""
    let icon = ""
    switch (status) {
        case "success":
            color = "green"
            icon = "fa-check-circle"
            break
        case "info":
            color = "blue"
            icon = "fa-exclamation-circle"
            break
        case "warning":
            color = "yellow"
            icon = "fa-exclamation-circle"
            break
        case "error":
            color = "red"
            icon = "fa-times"
            break
        default:
            icon = ""
            color = "white"
    }

    return (
        <div
            className="m-6 flex max-w-sm bg-white rounded-lg overflow-hidden shadow-inner shadow-xl"
            onClick={onDismiss}
        >
            <div
                className={`flex justify-center text-center items-center w-16 bg-${color}-500 text-xl`}
            >
                <i className={`text-white fas ${icon}`} />
            </div>
            <div className={`-mx-3 py-2 px-4 bg-${color}-200`}>
                <div className="mx-3">
                    {/* <span className={`text-${color}-500 font-semibold`}>{status}</span> */}
                    <p className="text-gray-600 text-md">{text}</p>
                </div>
            </div>
        </div>
    )
}

// Provider
// ==============================

let toastCount = 0

export function ToastProvider({ children }) {
    const [toasts, setToasts] = React.useState([])

    const addToast = (content) => {
        const id = toastCount++
        const toast = { content, id }
        setToasts([...toasts, toast])
    }
    const removeToast = (id) => {
        const newToasts = toasts.filter((t) => t.id !== id)
        setToasts(newToasts)
    }
    // avoid creating a new fn on every render
    const onDismiss = (id) => () => removeToast(id)

    return (
        <Ctx.Provider value={{ addToast, removeToast }}>
            {children}
            <ToastContainer>
                {toasts.map(({ content, id, ...rest }) => (
                    // <Toast key={id} Toast={Toast} onDismiss={onDismiss(id)} {...rest}>
                    //     {id + 1} &mdash; {content}
                    // </Toast>
                    <Toast
                        key={id}
                        Toast={Toast}
                        onDismiss={onDismiss(id)}
                        {...rest}
                        {...content}
                    />
                ))}
            </ToastContainer>
        </Ctx.Provider>
    )
}

// Consumer
// ==============================

export const useToasts = () => React.useContext(Ctx)

/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from "react"
import { Redirect, Route } from "react-router-dom"

import { AuthContext } from "../context/Auth"

const PrivateRoute = (props) => {
    const { currentUser } = useContext(AuthContext)
    const { location } = props
    const { pathname } = location
    // console.log(currentUser)
    return currentUser ? (
        <Route {...props} />
    ) : (
        <Redirect
            to={{
                pathname: "/login",
                state: { redirectedFrom: pathname },
            }}
        />
    )
}

export default PrivateRoute

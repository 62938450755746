import React, { useEffect, useState } from "react"
import app from "../config/firebase"

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
    // const [currentUser, setCurrentUser] = useState(null);

    const storedUser = JSON.parse(window.localStorage.getItem("storedUser"))
    const [currentUser, setCurrentUser] = useState(storedUser)

    useEffect(() => {
        // const storedUser = JSON.parse(window.localStorage.getItem('currentUser'))
        // if (storedUser) {
        //   setCurrentUser(storedUser)
        // } else {
        // app.auth().onAuthStateChanged(setCurrentUser);
        app.auth().onAuthStateChanged(async (user) => {
            // console.log(user);
            if (user) {
                setCurrentUser({
                    uid: user.uid,
                    email: user.email,
                    name: user.displayName,
                    // admin: isAdmin,
                    token: user.refreshToken,
                })
            }
        })
        // }
    }, [])

    useEffect(() => {
        window.localStorage.setItem("storedUser", JSON.stringify(currentUser))
    }, [currentUser])

    const handleLogin = async (data) => {
        // console.log(data)
        const { email, password } = data
        // setCurrentUser(userData)
        try {
            await app.auth().signInWithEmailAndPassword(email, password)
            return true
            // history.push('/');
        } catch (error) {
            // alert(error);
            console.error(error)
            return false
            // setFormError(
            //   'Sorry but we do not recognize that email/password combination. Please try again',
            // );
        }
    }

    const handleLogout = async () => {
        // console.log('logout user')
        try {
            await app.auth().signOut()
            setCurrentUser(null)
            return true
        } catch (error) {
            // alert(error);
            console.error(error)
            return false
            // setFormError(
            //   'Sorry but we do not recognize that email/password combination. Please try again',
            // );
        }
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                handleLogin,
                handleLogout,
            }}
        >
            {typeof currentUser === "undefined" ? (
                <div className="flex h-screen items-center justify-center text-gray-600">
                    Loading....
                </div>
            ) : (
                children
            )}
        </AuthContext.Provider>
    )
}

import React from "react"

export default function FooterSmall() {
    return (
        <>
            <footer className="bottom-0 bg-white py-6">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="px-4">
                            <div className="text-sm text-gray-600 font-semibold py-1">
                                Copyright © {new Date().getFullYear()}{" "}
                                <a
                                    href="https://www.ncf.bb"
                                    className="text-gray-600 text-sm font-semibold py-1"
                                >
                                    <span className="hover:text-yellow-500">
                                        NCF Barbados
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="px-4 text-xs">
                            <p className="text-gray-600">
                                Developed by{" "}
                                <span className="p-1 rounded">
                                    <a
                                        href="https://www.webstart101.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-600 hover:text-blue-500"
                                    >
                                        Webstart
                                    </a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

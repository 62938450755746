import React from "react"
import "./App.css"
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom"
import PageWrapper from "./components/PageWrapper"
import Page404 from "./components/Page404"

import Home from "./screens/Home"
import Page from "./screens/Page"
import Login from "./screens/Login"
import SignUp from "./screens/SignUp"
import ForgotPassword from "./screens/ForgotPassword"
import Profile from "./screens/Profile"
import Admin from "./screens/Admin"

import { AuthProvider } from "./context/Auth"
import { ToastProvider } from "./context/Toasts"

import PrivateRoute from "./utils/PrivateRoute"

const App = () => (
    <ToastProvider>
        <AuthProvider>
            <Router>
                <PageWrapper>
                    <Switch>
                        {/* <PrivateRoute exact path="/" component={Home} /> */}
                        <Route exact path="/" component={Home} />
                        <Route exact path="/home" component={Home} />
                        <Route exact path="/canvas" component={Page} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/signup" component={SignUp} />
                        <Route exact path="/forgotpassword" component={ForgotPassword} />
                        <Route exact path="/notfound" component={Page404} />
                        <PrivateRoute
                            component={() => (
                                <Switch>
                                    {/* <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/statistics" component={Statistics} /> */}
                                    <Route exact path="/admin" component={Admin} />
                                    {/* <Route path="/contacts/:contactId" component={Contacts} /> */}
                                    {/* <Route exact path="/dashboard" component={Dashboard} /> */}
                                    <Route exact path="/profile" component={Profile} />
                                    <Redirect from="*" to="/notfound" />
                                </Switch>
                            )}
                        />
                        <Route component={Page404} />
                    </Switch>
                </PageWrapper>
            </Router>
        </AuthProvider>
    </ToastProvider>
)

export default App

import React, { useState, useEffect } from "react"
import { List } from "react-content-loader"
import { format } from "date-fns"
import Swal from "sweetalert2"

import Modal from "../components/Modal"
import PageForm from "../components/PageForm"
import BookForm from "../components/BookForm"
import ImgLoader from "../components/ImgLoader"

import firebase from "../config/firebase"

import guid from "../utils"

const Admin = () => {
    const [isModalOpen, toggleModal] = useState(false)
    const [showPageModal, togglePageModal] = useState(false)
    const [modalContent, setModalContent] = useState(null)
    // const [fieldValues, setFieldValues] = useState({ book_title: '', book_description: '' })
    const [bookList, setBookList] = useState(null)
    const [pageList, setPageList] = useState([])
    const [selectedBook, setSelectedBook] = useState(null)
    const [selectedPage, setSelectedPage] = useState(null)

    const db = firebase.firestore()

    const fetchData = async () => {
        // const db = firebase.firestore();

        const bookData = await db
            .collection("books")
            .where("archived", "==", false)
            .get()

        const books = bookData.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }))

        setBookList(books.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)))
        setSelectedBook(books[0])
    }

    const fetchPages = async (bookId) => {
        try {
            const pagesData = await db
                .collection("books")
                .doc(bookId)
                .collection("pages")
                .where("archived", "==", false)
                .get()

            const pages = pagesData.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }))
            // if (pages?.length > 0) {
            //   setPageList(pages)
            // } else {
            //   setPageList(null)
            // }

            setPageList(pages)

            // handlePageModal();
            // fetchData();
        } catch (error) {
            // console.error(error);
            console.error("Error finding document: ", error)

            // setFormError(error.message);
        }
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // Fetch pages whenever a book is selected
        // console.log(selectedBook)
        if (selectedBook) {
            fetchPages(selectedBook.id)
        }
        // eslint-disable-next-line
    }, [selectedBook])

    const handleModal = (book) => {
        // if (!isModalOpen) {
        //   setModalContent({ title: 'Add Book' })
        // } else {
        //   setModalContent(null)
        // }
        // console.log(book)

        if (book) {
            setModalContent({ ...modalContent, data: book })
        } else {
            setModalContent(null)
        }

        console.log("handle modal", isModalOpen)

        // console.log(modalContent)
        toggleModal(!isModalOpen)
    }

    const handlePageModal = (page) => {
        if (page) {
            setSelectedPage(page)
        } else {
            setSelectedPage(null)
        }
        togglePageModal(!showPageModal)
    }

    const uploadPhotoAsync = (uri, fileName) =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (res) => {
            // const response = await fetch(uri);
            // const file = await response.blob();

            const uploadRef = firebase.storage().ref(fileName)

            await uploadRef.put(uri)
            const downloadURL = await uploadRef.getDownloadURL()
            res(downloadURL)
        })

    // Add new item to shopping list
    const handleAddPage = async (itemObj, img) => {
        // const db = firebase.firestore();

        // Hide modal and show loading
        handlePageModal()
        Swal.showLoading()

        const objId = guid()
        const data = { ...itemObj }

        // data.book_id = selectedBook.id
        // data.dateAdded
        // data.archived = false
        // Upload photo image and retrieve location
        if (img && Object.keys(img).length < 1) {
            const remoteUri = await uploadPhotoAsync(img, `${objId}`)
            data.image = remoteUri
        }
        console.log("add new page info", data)
        try {
            // Add book id to added page record
            await db
                .collection("books")
                .doc(selectedBook.id)
                .collection("pages")
                .add({
                    ...data,
                    book_id: selectedBook.id,
                    dateAdded: format(new Date(), "yyyy-MM-dd HH:mm"),
                    archived: false,
                })
                .then(() => {
                    // Hide loading
                    Swal.close()
                    // Refresh data
                    fetchPages(selectedBook.id)
                })
            // handlePageModal();
            // fetchPages(selectedBook.id);
        } catch (error) {
            console.error("Error adding page", error)
            // setFormError(error.message);
        }
    }

    const handleUpdatePage = async (itemObj, img) => {
        // const db = firebase.firestore();
        // const newItemID = guid();

        // Hide modal and show loading
        handlePageModal()
        Swal.showLoading()

        if (itemObj) {
            const objId = guid()
            const data = { ...itemObj }
            if (img && Object.keys(img).length < 1) {
                const remoteUri = await uploadPhotoAsync(img, `${objId}`)
                data.image = remoteUri
            }
            console.log("update page info", data)
            try {
                // await db.collection("customers").doc(currentUser.uid).collection('cart').add({ name: newItem, quantity: 1 });
                await db
                    .collection("books")
                    .doc(selectedBook.id)
                    .collection("pages")
                    .doc(data.id)
                    .update(data)
                    .then(() => {
                        // Hide loading
                        Swal.close()
                        // Refresh data
                        fetchPages(selectedBook.id)
                    })
            } catch (error) {
                // alert(error);
                console.error("Error updating page", error)
            }
        }
    }

    const handlePageOrder = async (itemObj, direction) => {
        // const db = firebase.firestore();
        // const newItemID = guid();

        // Hide modal and show loading
        // handlePageModal()
        // Swal.showLoading()

        if (itemObj) {
            const objId = guid()
            const data = { ...itemObj }

            const currentIndex = data.index
            let newIndex
            if (currentIndex) {
                if (direction === "up") {
                    //Make sure that the page index is not reduced to zero
                    if (currentIndex > 1) {
                        newIndex = currentIndex - 1
                    }
                } else {
                    //Make sure that the page index is not increased past the number of pages
                    if (currentIndex < pageList.length) {
                        newIndex = currentIndex + 1
                    }
                }
            } else {
                newIndex = 1
            }

            console.log("update page info", data)
            try {
                // await db.collection("customers").doc(currentUser.uid).collection('cart').add({ name: newItem, quantity: 1 });
                await db
                    .collection("books")
                    .doc(selectedBook.id)
                    .collection("pages")
                    .doc(data.id)
                    .update({ ...data, index: newIndex })
                    .then(() => {
                        // Hide loading
                        // Swal.close()
                        // // Refresh data
                        // fetchPages(selectedBook.id)
                    })
            } catch (error) {
                // alert(error);
                console.error("Error updating page", error)
            }
        }
    }

    const handleAddBook = async (itemObj, img) => {
        // const data = { ...fieldValues }
        if (!itemObj) {
            return null
        }
        const data = { ...itemObj }
        const objId = guid()

        // Hide modal and show loading
        handleModal()
        Swal.showLoading()

        if (img && Object.keys(img).length < 1) {
            const remoteUri = await uploadPhotoAsync(img, `${objId}`)
            data.image = remoteUri
        }

        try {
            await db
                .collection("books")
                .add({
                    title: data.book_title,
                    description: data.book_description,
                    image: data.image,
                    active: true,
                    archived: false,
                    dateAdded: format(new Date(), "yyyy-MM-dd HH:mm"),
                })
                .then((docRef) => {
                    console.log("Document written with ID: ", docRef.id)

                    // Hide loading
                    Swal.close()
                    // Refresh data
                    fetchData()
                })
                .catch((error) => {
                    console.error("Error adding document: ", error)
                })
        } catch (error) {
            console.error("Error adding book", error)
            // setFormError(error.message);
        }
    }

    const handleUpdateBook = async (itemObj, img) => {
        // const db = firebase.firestore();
        // const newItemID = guid();

        if (itemObj) {
            const objId = guid()
            const data = { ...itemObj }

            // Hide modal and show loading
            handleModal()
            Swal.showLoading()

            if (img && Object.keys(img).length < 1) {
                const remoteUri = await uploadPhotoAsync(img, `${objId}`)
                data.image = remoteUri
            }
            console.log("update info", data)
            try {
                // await db.collection("customers").doc(currentUser.uid).collection('cart').add({ name: newItem, quantity: 1 });
                await db
                    .collection("books")
                    .doc(data.id)
                    .update({
                        title: data.book_title,
                        description: data.book_description,
                        image: data.image,
                    })

                // Hide loading
                Swal.close()
                // Refresh data
                fetchData()
            } catch (error) {
                // alert(error);
                console.error("Error updating book", error)
            }

            // setItems([...items, { id: newItemID, name: newItem, quantity: 1 }])
        }

        // setNewItem('')
    }

    const handleSelectBook = (item) => {
        setSelectedBook(item)

        // Collect pages
        fetchPages(item.id)
    }

    const handleRemoveBook = async (bookId) => {
        console.log("remove book", bookId)

        Swal.fire({
            title: "Are you sure?",
            text: "This will delete the book",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, remove!",
            cancelButtonText: "No, not yet",
        }).then(async (result) => {
            if (result.value) {
                try {
                    // await db.collection("customers").doc(currentUser.uid).collection('cart').add({ name: newItem, quantity: 1 });
                    await db
                        .collection("books")
                        .doc(bookId)
                        .update({
                            archived: true,
                        })

                    handleModal()

                    Swal.fire("Removed!", "Book has been removed.", "success").then(
                        (result) => {
                            fetchData()
                        }
                    )
                } catch (error) {
                    // alert(error);
                    console.error("Error removing book", error)
                }
            }
        })
    }

    const handleRemovePage = (pageId) => {
        console.log("remove page", pageId)

        Swal.fire({
            title: "Are you sure?",
            text: "This will remove this page from the book",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, remove!",
            cancelButtonText: "No, not yet",
        }).then(async (result) => {
            if (result.value) {
                try {
                    // await db.collection("customers").doc(currentUser.uid).collection('cart').add({ name: newItem, quantity: 1 });
                    await db
                        .collection("books")
                        .doc(selectedBook.id)
                        .collection("pages")
                        .doc(pageId)
                        .update({
                            archived: true,
                        })

                    handlePageModal()

                    Swal.fire("Removed!", "Page has been removed.", "success").then(
                        (result) => {
                            fetchData()
                        }
                    )
                } catch (error) {
                    // alert(error);
                    console.error("Error removing page", error)
                }
            }
        })
    }

    return (
        <div className="bg-gray-200 h-full pt-2">
            <div className="w-full container mx-auto flex h-auto">
                <div className="w-1/5 h-full m-5 p-3">
                    <div className="p-6">
                        <div className="flex justify-between">
                            <h2 className="text-2xl text-gray-900">Books</h2>
                            <button
                                type="button"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                onClick={() => {
                                    handleModal()
                                }}
                            >
                                + New
                            </button>
                        </div>

                        <div className="mt-2 border-t border-gray-400 pt-4">
                            <ul className="list-none">
                                {bookList ? (
                                    bookList?.length > 0 ? (
                                        bookList?.map((item) => (
                                            <div
                                                key={item.id}
                                                role="presentation"
                                                onClick={() => handleSelectBook(item)}
                                            >
                                                <li className="flex items-center justify-between p-3 hover:bg-blue-200 hover:text-blue-500">
                                                    <div className="text-md">
                                                        {item.title}
                                                    </div>
                                                </li>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="bg-gray-300 text-center p-5">
                                            There are no books
                                        </div>
                                    )
                                ) : (
                                    <div className="flex justify-center items-center p-5">
                                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
                                        <span className="sr-only">Loading books...</span>
                                    </div>
                                )}
                                {}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="w-4/5 bg-white shadow-lg h-full m-5 p-3">
                    <div className="w-full p-5">
                        <div className="flex justify-between">
                            <h2 className="text-2xl text-gray-900">Book Information</h2>
                            {selectedBook && (
                                <button
                                    type="button"
                                    className="bg-gray-200 hover:bg-gray-400 shadow-lg border border-gray-400 text-gray-600 text-sm font-semibold py-2 px-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => handleModal(selectedBook)}
                                >
                                    Edit
                                </button>
                            )}
                        </div>

                        {selectedBook ? (
                            <>
                                <div className="flex justify-between">
                                    {/* <label className="mt-4 block uppercase text-gray-700 text-xs font-bold mb-2">Id</label>
                  <p>{selectedBook.id}</p> */}
                                    <div>
                                        <label className="mt-4 block uppercase text-gray-700 text-xs font-bold mb-2">
                                            Title
                                        </label>
                                        <p>{selectedBook.title}</p>
                                        <label className="mt-4 block uppercase text-gray-700 text-xs font-bold mb-2">
                                            Description
                                        </label>
                                        <p>{selectedBook.description}</p>
                                    </div>
                                    <img
                                        src={selectedBook.image}
                                        className="h-32 block object-center object-cover"
                                        alt={selectedBook.title}
                                    />
                                </div>

                                {pageList && (
                                    <div className="mt-5 pt-5 border-t border-gray-300">
                                        <div className="flex justify-between">
                                            <div>
                                                <h2 className="text-2xl text-gray-900">
                                                    Pages
                                                </h2>
                                                <p>
                                                    Please note that pages will be
                                                    displayed in the order that they were
                                                    added
                                                </p>
                                            </div>

                                            <button
                                                type="button"
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                                onClick={() => handlePageModal()}
                                            >
                                                + Page
                                            </button>
                                        </div>
                                        {pageList ? (
                                            pageList.length > 0 ? (
                                                pageList?.map((item) => (
                                                    <div
                                                        role="presentation"
                                                        key={item.id}
                                                        // onClick={() =>
                                                        //     handlePageModal(item)
                                                        // }
                                                        className="flex items-center justify-between rounded-lg border border-gray-300 p-3 my-3"
                                                    >
                                                        <div>
                                                            <p>{item.page_description}</p>
                                                            <div className="inline-flex pt-3">
                                                                <button
                                                                    className="bg-gray-200 hover:bg-gray-400 shadow-lg border border-gray-400 text-gray-600 text-sm font-semibold py-2 px-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                                                    onClick={() =>
                                                                        handlePageModal(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    Edit
                                                                </button>
                                                                {/* <button
                                                                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                                                                    onClick={() => handlePageOrder(item, 'up')}
                                                                >
                                                                    Move up
                                                                </button>
                                                                <button
                                                                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                                                                    onClick={() => handlePageOrder(item, 'down')}
                                                                >
                                                                    Move down
                                                                </button> */}
                                                            </div>
                                                        </div>
                                                        <div
                                                            role="presentation"
                                                            onClick={() =>
                                                                handlePageModal(item)
                                                            }
                                                        >
                                                            <img
                                                                src={item.image}
                                                                height="100"
                                                                width="100"
                                                                alt={
                                                                    item.page_photo.file
                                                                        .name
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="bg-gray-300 text-center p-5">
                                                    There are no pages
                                                </div>
                                            )
                                        ) : (
                                            <ImgLoader />
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            <List />
                        )}
                    </div>
                </div>

                {isModalOpen && (
                    <Modal
                        small
                        // title={modalContent?.title}
                        title={
                            modalContent?.data &&
                            Object.entries(modalContent.data).length > 0
                                ? "Edit Book"
                                : "Add Book"
                        }
                        isOpen={isModalOpen}
                        toggleModal={handleModal}
                        // content={modalContent}
                        onConfirm={handleModal}
                    >
                        <BookForm
                            editing={
                                !!(
                                    modalContent?.data &&
                                    Object.entries(modalContent.data).length > 0
                                )
                            }
                            // editing={(Object.entries(selectedBook).length > 0) ? true : false}
                            productInfo={modalContent?.data}
                            onAdd={handleAddBook}
                            onUpdate={handleUpdateBook}
                            onRemove={handleRemoveBook}
                            onCancel={handleModal}
                        />
                    </Modal>
                )}

                {showPageModal && (
                    <Modal
                        small
                        title={selectedPage ? "Edit Page" : "Add Page"}
                        // title={item ? "Edit Item" : "Add Item"}
                        isOpen={showPageModal}
                        toggleModal={handlePageModal}
                        // content={modalContent}
                        onConfirm={handlePageModal}
                    >
                        <PageForm
                            editing={!!selectedPage}
                            productInfo={selectedPage}
                            onAdd={handleAddPage}
                            onUpdate={handleUpdatePage}
                            onRemove={handleRemovePage}
                            onCancel={handlePageModal}
                        />
                    </Modal>
                )}
            </div>
        </div>
    )
}
export default Admin

import React from "react"

const Modal = (props) => {
    const { isOpen, toggleModal, title, children, onSave, confirmText } = props
    return (
        <div
            className={`${
                isOpen ? "modal-active" : "opacity-0 pointer-events-none "
            }modal fixed z-50 w-full h-full top-0 left-0 flex items-center justify-center py-16`}
        >
            <div
                className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
                role="presentation"
                onClick={toggleModal}
            />
            <div className="absolute top-0 mt-10 bg-white rounded-lg w-3/4 h-auto z-50">
                <div className="flex flex-col items-center justify-center h-full ">
                    <div className="flex-grow flex flex-col h-full overflow-auto w-full ">
                        <div className="flex w-full justify-center text-white bg-blue-600 p-4">
                            <div className="font-medium text-lg">{title}</div>
                            {toggleModal && (
                                <svg
                                    onClick={toggleModal}
                                    className="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 18 18"
                                >
                                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                                </svg>
                            )}
                        </div>

                        <div>{children}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal

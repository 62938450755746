import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2"

import firebase from "../config/firebase"

import DrawingCanvas from "../components/DrawingCanvas"
import Modal from "../components/Modal"

const Home = () => {
    const db = firebase.firestore()
    // Set canvas defaults
    const [markerColor] = useState("rgba(233,30,99,1)")
    const [markerSize] = useState(30)

    let history = useHistory()

    const [isLoadingBooks, toggleLoadingBooks] = useState(false)
    const [isLoadingPages, toggleLoadingPages] = useState(false)
    const [isModalOpen, toggleModal] = useState(false)
    const [showPagesModal, togglePagesModal] = useState(false)
    const [bookList, setBookList] = useState(null)
    const [pageList, setPageList] = useState(null)
    const [selectedBook, setSelectedBook] = useState(null)
    const [selectedPage, setPage] = useState("")
    const [baseImage, setBaseImage] = useState("")

    const handleModal = () => {
        toggleModal(!isModalOpen)
    }

    const fetchData = async () => {
        // const db = firebase.firestore();
        toggleLoadingBooks(true)
        const bookData = await db
            .collection("books")
            .where("archived", "==", false)
            .get()

        const books = bookData.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }))

        setBookList(books)
        toggleLoadingBooks(false)
        // setSelectedBook(books[0])
    }

    const fetchPages = async (bookId) => {
        toggleLoadingPages(true)
        try {
            const pagesData = await db
                .collection("books")
                .doc(bookId)
                .collection("pages")
                .where("archived", "==", false)
                .get()

            const pages = pagesData.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }))
            console.log("pages", pages)
            setPageList(pages)
            toggleLoadingPages(false)
        } catch (error) {
            // console.error(error);
            console.error("Error finding pages: ", error)
        }
    }

    // useEffect(() => {
    //     // console.log(linesObject)

    //     console.log("save current page")
    //     // Save user progress to local storage
    //     window.localStorage.setItem("currentPage", JSON.stringify(selectedPage))

    // }, [selectedPage])

    useEffect(() => {
        // Display intro modal if there's no drawing
        const storedDrawing = window.localStorage.getItem("baseImage")
        // console.log(storedDrawing, storedDrawing?.length)
        if (!storedDrawing) {
            toggleModal(true)
        } else {
            setBaseImage(storedDrawing)
        }

        const savedPage = window.localStorage.getItem("currentPage")
        // console.log("selected page", savedPage)
        if (savedPage) {
            setPage(JSON.parse(savedPage))
        }

        const savedBook = window.localStorage.getItem("currentBook")
        // console.log("selected book", savedBook)
        if (savedBook) {
            let bookData = JSON.parse(savedBook)
            setSelectedBook(bookData)
            fetchPages(bookData.id)
        }

        fetchData()
        // eslint-disable-next-line
    }, [])

    const handleSelectBook = (book) => {
        console.log("selected book", book)
        setSelectedBook(book)
        window.localStorage.setItem("currentBook", JSON.stringify(book))

        fetchPages(book.id)

        toggleModal(!isModalOpen)
        togglePagesModal(!showPagesModal)
    }

    const handleSelectPage = (page) => {
        console.log(page)
        //Save page selection
        setPage(page)
        window.localStorage.setItem("currentPage", JSON.stringify(page))
        //Save image selection
        setBaseImage(page.image)

        //navigate to canvas page
        history.push({
            pathname: "/canvas",
            book: selectedBook,
            page: page,
        })

        // togglePagesModal(!showPagesModal)
    }

    const goBack = () => {
        togglePagesModal(!showPagesModal)
        toggleModal(!isModalOpen)
    }

    // const handlePreviousPage = () => {
    //     togglePagesModal(!showPagesModal)
    // }

    const handleSwitchPage = () => {
        // console.log("selected book", selectedBook)
        togglePagesModal(!showPagesModal)
        // Swal.fire({
        //     title: "Are you sure?",
        //     text:
        //         "You may lose your artwork if you switch! Please save and download your art first",
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonText: "Yes",
        //     cancelButtonText: "No I don't want to switch",
        //     // confirmButtonColor: '#d33',
        // }).then((result) => {
        //     if (result.value) {
        //         togglePagesModal(!showPagesModal)
        //     }
        // })
    }

    const handleSwitchBook = () => {
        toggleModal(true)
        // Swal.fire({
        //     title: "Are you sure?",
        //     text:
        //         "You may lose your artwork if you switch! Please save and download your art first",
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonText: "Yes",
        //     cancelButtonText: "No I don't want to switch",
        //     // confirmButtonColor: '#d33',
        // }).then((result) => {
        //     if (result.value) {
        //         toggleModal(true)
        //     }
        // })
    }

    return (
        <div className="flex flex-col w-full h-auto min-h-full bg-orange-300">
            <section className="text-gray-700 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-20">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                            {selectedBook?.title}
                        </h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                            {selectedBook?.description}
                        </p>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2">
                        {pageList ? (
                            pageList?.length > 0 ? (
                                pageList?.map((item) => (
                                    <div key={item.id} className="p-4">
                                        <div
                                            className="flex relative bg-white rounded"
                                            onClick={() => handleSelectPage(item)}
                                        >
                                            {item.image ? (
                                                <img
                                                    alt="gallery"
                                                    className="absolute inset-0 w-full h-full object-cover object-center"
                                                    src={item.image}
                                                />
                                            ) : (
                                                <div className="bg-gray-300 h-full"></div>
                                            )}

                                            <div className="h-64 px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                                                {/* <h2 className="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                                                    {item.page_title}
                                                </h2> */}
                                                <h1 className="title-font text-lg font-medium text-gray-900 mb-3 uppercase">
                                                    {item.page_title}
                                                </h1>
                                                <p className="leading-relaxed h-full overflow-y-auto">
                                                    {item.page_description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="bg-gray-300 flex justify-center items-center p-5">
                                    There are no pages available
                                </div>
                            )
                        ) : (
                            <div className="flex justify-center items-center p-5">
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
                                <span className="sr-only">Loading pages...</span>
                            </div>
                        )}
                    </div>
                    <div className="mt-10 flex justify-center items-center">
                        <button
                            className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded-full text-sm md:text-lg"
                            onClick={handleSwitchBook}
                        >
                            Choose a different book
                        </button>
                    </div>
                </div>
            </section>

            <Modal
                title="Choose a Book"
                isOpen={isModalOpen}
                // toggleModal={handleModal}
                // content={modalContent}
                onConfirm={handleModal}
                className="text-center"
            >
                <div className="w-full overflow-visible" style={{ height: "500px" }}>
                    {bookList ? (
                        bookList?.length > 0 ? (
                            bookList?.map((item) => (
                                <div
                                    key={item.id}
                                    className="grid grid-cols-1 sm:grid-cols-2 border border-gray-200 m-3 mb-5"
                                >
                                    <div className="h-full overflow-auto">
                                        <div className="p-2">
                                            <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 uppercase">
                                                Title
                                            </h2>
                                            <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                                                {item.title}
                                            </h1>
                                            <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 uppercase">
                                                Description
                                            </h2>
                                            <p className="leading-relaxed mb-3 h-full overflow">
                                                {item.description}
                                            </p>
                                            <div className="flex items-center flex-wrap ">
                                                <button
                                                    className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded-full text-sm md:text-lg"
                                                    onClick={() => handleSelectBook(item)}
                                                >
                                                    View Pages
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse bg-gray-300">
                                        {item.image && (
                                            <img
                                                className="lg:h-full md:h-full w-full object-cover object-center"
                                                src={item.image}
                                                alt={item.title}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="bg-gray-300 flex justify-center items-center p-5">
                                There are no books available
                            </div>
                        )
                    ) : (
                        <div className="flex justify-center items-center p-5">
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
                            <span className="sr-only">Loading books...</span>
                        </div>
                    )}
                </div>
            </Modal>

            {/* <Modal
                title={`Viewing Pages of Book: ${selectedBook?.title}`}
                isOpen={showPagesModal}
                toggleModal={() => togglePagesModal(!showPagesModal)}
            >
                <div className="text-center">
                    <p className="py-6">{selectedBook?.description}</p>
                    <div
                        style={{ height: "300px" }}
                        className="overflow-y-auto flex flex-wrap justify-center"
                    >
                        {pageList ? (
                            pageList?.length > 0 ? (
                                pageList?.map((item) => (
                                    <div
                                        key={item.id}
                                        className="text-center"
                                        role="presentation"
                                        onClick={() => handleSelectPage(item)}
                                    >
                                        <div className="m-2 block relative h-48 rounded overflow-hidden bg-gray-200 bg-opacity-25 flex flex-col justify-center items-center">
                                            <img
                                                src={item.image}
                                                width="200"
                                                alt={item.id}
                                            />
                                        </div>
                                        <p className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            {item.title}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                    <div className="bg-gray-300 flex justify-center items-center p-5">
                                        There are no pages available
                                    </div>
                                )
                        ) : (
                                <div className="flex justify-center items-center p-5">
                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
                                    <span className="sr-only">Loading pages...</span>
                                </div>
                            )}
                    </div>
                    <div className="flex justify-start">
                        <button
                            type="button"
                            className="text-gray-600 font-normal py-2 px-4 mt-5 rounded"
                            onClick={goBack}
                        >
                            <i className="fas fa-chevron-left" /> View Books
                        </button>
                    </div>
                </div>
            </Modal> */}
        </div>
    )
}

export default Home

const guid = () => {
    const s4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)
    // return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

// export const rgba2hex = (orig) => {
//     var a, isPercent,
//         rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
//         alpha = (rgb && rgb[4] || "").trim(),
//         hex = rgb ?
//             (rgb[1] | 1 << 8).toString(16).slice(1) +
//             (rgb[2] | 1 << 8).toString(16).slice(1) +
//             (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

//     if (alpha !== "") {
//         a = alpha;
//     } else {
//         a = 01;
//     }
//     // multiply before convert to HEX
//     a = ((a * 255) | 1 << 8).toString(16).slice(1)
//     hex = hex + a;

//     return hex;
// }

export const crayolaColours = [
    {
        hex: "#EE204D",
        name: "Red",
        rgb: "(239, 32,77)",
    },
    {
        hex: "#fce883",
        name: "yellow",
        rgb: "(252,232,131)",
    },
    {
        hex: "#1f75fe",
        name: "blue",
        rgb: "(31,117,254)",
    },
    {
        hex: "#b5674d",
        name: "brown",
        rgb: "(180,103,77)",
    },
    {
        hex: "#ff7538",
        name: "orange",
        rgb: "(255,117,56)",
    },
    {
        hex: "#1cac78",
        name: "green",
        rgb: "(28,172,120)",
    },
    {
        hex: "#926eae",
        name: "purple",
        rgb: "(146,110,174)",
    },
    {
        hex: "#232323",
        name: "black",
        rgb: "(35,35,35)",
    },
    {
        hex: "#EE204D",
        name: "Red",
        rgb: "(239, 32,77)",
    },
    {
        hex: "#fce883",
        name: "yellow",
        rgb: "(252,232,131)",
    },
    {
        hex: "#1f75fe",
        name: "blue",
        rgb: "(31,117,254)",
    },
    {
        hex: "#b5674d",
        name: "brown",
        rgb: "(180,103,77)",
    },
    {
        hex: "#ff7538",
        name: "orange",
        rgb: "(255,117,56)",
    },
    {
        hex: "#1cac78",
        name: "green",
        rgb: "(28,172,120)",
    },
    {
        hex: "#926eae",
        name: "purple",
        rgb: "(146,110,174)",
    },
    {
        hex: "#232323",
        name: "black",
        rgb: "(35,35,35)",
    },
    {
        hex: "#EE204D",
        name: "Red",
        rgb: "(239, 32,77)",
    },
    {
        hex: "#fce883",
        name: "yellow",
        rgb: "(252,232,131)",
    },
    {
        hex: "#1f75fe",
        name: "blue",
        rgb: "(31,117,254)",
    },
    {
        hex: "#b5674d",
        name: "brown",
        rgb: "(180,103,77)",
    },
    {
        hex: "#ff7538",
        name: "orange",
        rgb: "(255,117,56)",
    },
    {
        hex: "#1cac78",
        name: "green",
        rgb: "(28,172,120)",
    },
    {
        hex: "#926eae",
        name: "purple",
        rgb: "(146,110,174)",
    },
    {
        hex: "#232323",
        name: "black",
        rgb: "(35,35,35)",
    },
    {
        hex: "#EE204D",
        name: "Red",
        rgb: "(239, 32,77)",
    },
    {
        hex: "#fce883",
        name: "yellow",
        rgb: "(252,232,131)",
    },
    {
        hex: "#1f75fe",
        name: "blue",
        rgb: "(31,117,254)",
    },
    {
        hex: "#b5674d",
        name: "brown",
        rgb: "(180,103,77)",
    },
    {
        hex: "#ff7538",
        name: "orange",
        rgb: "(255,117,56)",
    },
    {
        hex: "#1cac78",
        name: "green",
        rgb: "(28,172,120)",
    },
    {
        hex: "#926eae",
        name: "purple",
        rgb: "(146,110,174)",
    },
    {
        hex: "#232323",
        name: "black",
        rgb: "(35,35,35)",
    },
    {
        hex: "#EE204D",
        name: "Red",
        rgb: "(239, 32,77)",
    },
    {
        hex: "#fce883",
        name: "yellow",
        rgb: "(252,232,131)",
    },
    {
        hex: "#1f75fe",
        name: "blue",
        rgb: "(31,117,254)",
    },
    {
        hex: "#b5674d",
        name: "brown",
        rgb: "(180,103,77)",
    },
    {
        hex: "#ff7538",
        name: "orange",
        rgb: "(255,117,56)",
    },
    {
        hex: "#1cac78",
        name: "green",
        rgb: "(28,172,120)",
    },
    {
        hex: "#926eae",
        name: "purple",
        rgb: "(146,110,174)",
    },
    {
        hex: "#232323",
        name: "black",
        rgb: "(35,35,35)",
    },
    {
        hex: "#EE204D",
        name: "Red",
        rgb: "(239, 32,77)",
    },
    {
        hex: "#fce883",
        name: "yellow",
        rgb: "(252,232,131)",
    },
    {
        hex: "#1f75fe",
        name: "blue",
        rgb: "(31,117,254)",
    },
    {
        hex: "#b5674d",
        name: "brown",
        rgb: "(180,103,77)",
    },
    {
        hex: "#ff7538",
        name: "orange",
        rgb: "(255,117,56)",
    },
    {
        hex: "#1cac78",
        name: "green",
        rgb: "(28,172,120)",
    },
    {
        hex: "#926eae",
        name: "purple",
        rgb: "(146,110,174)",
    },
    {
        hex: "#232323",
        name: "black",
        rgb: "(35,35,35)",
    },
    {
        hex: "#EE204D",
        name: "Red",
        rgb: "(239, 32,77)",
    },
    {
        hex: "#fce883",
        name: "yellow",
        rgb: "(252,232,131)",
    },
    {
        hex: "#1f75fe",
        name: "blue",
        rgb: "(31,117,254)",
    },
    {
        hex: "#b5674d",
        name: "brown",
        rgb: "(180,103,77)",
    },
    {
        hex: "#ff7538",
        name: "orange",
        rgb: "(255,117,56)",
    },
    {
        hex: "#1cac78",
        name: "green",
        rgb: "(28,172,120)",
    },
    {
        hex: "#926eae",
        name: "purple",
        rgb: "(146,110,174)",
    },
    {
        hex: "#232323",
        name: "black",
        rgb: "(35,35,35)",
    },
]

export default guid

import React, { useState, useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import { Link } from "react-router-dom"
import { Stage, Layer, Line, Image, Text } from "react-konva"
import Dimensions from "react-dimensions"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import useImage from "use-image"

//import { CirclePicker, GithubPicker, TwitterPicker } from "react-color"
import { GithubPicker } from "react-color"
import Swal from "sweetalert2"

import ColorPicker from "./ColorPicker"
import { useToasts } from "../context/Toasts"
import guid from "../utils"

// Canvas styles
import "./canvas.css"

// Useful function to convert rgba color values to hex
const rgba2hex = (orig) => {
    var a,
        rgb = orig.replace(/\s/g, "").match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
        alpha = ((rgb && rgb[4]) || "").trim(),
        hex = rgb
            ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
              (rgb[2] | (1 << 8)).toString(16).slice(1) +
              (rgb[3] | (1 << 8)).toString(16).slice(1)
            : orig

    if (alpha !== "") {
        a = alpha
    } else {
        a = parseInt("01")
    }
    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1)
    hex = hex + a

    return hex
}

const arr_colours = [
    "#B80000FF",
    "#DB2E00FF",
    "#FCCB00FF",
    "#008B02FF",
    "#006B76FF",
    "#1273DEFF",
    "#004DCFFF",
    "#5300EBFF",
    "#EB9694FF",
    "#FAD0C3FF",
    "#FEF3BDFF",
    "#C1E1C5FF",
    "#BEDADCFF",
    "#C4DEF6FF",
    "#BED3F3FF",
    "#D4C4FBFF",
    "#D2A56D",
    "#CE8B54",
    "#BE7E4A",
    "#96613D",
    "#82502E",
    // "#A1887FFF",
    // "#795548FF",
    "#EEEEEE",
    "#CCCCCC",
    "#000000",
]

const Popover = ({ children, onClose, open }) =>
    open
        ? ReactDOM.createPortal(
              <div
                  className="absolute z-50 bg-white rounded shadow p-3"
                  style={{ top: 100, right: 100 }}
              >
                  <div className="ml-auto" onClick={onClose}>
                      <i className="fas fa-times text-gray-600"></i>
                  </div>
                  {children}
              </div>,
              document.body
          )
        : null

const DrawingCanvas = (props) => {
    const [showBrushControls, toggleBrushControls] = useState(false)
    const [showZoomControls, toggleZoomControls] = useState(false)
    const [size, setSize] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const [brushOpacity, setBrushOpacity] = useState(1)
    const initialColor = `rgba(233,30,99,${brushOpacity})`
    const [lines, setLines] = useState([])
    const [linesObject, setLinesObject] = useState([])
    const [isDrawing, setIsDrawing] = useState(false)
    const [isFullView, setIsFullView] = useState(false)
    const [markerColor, setMarkerColor] = useState(initialColor)
    const [isMarker, toggleMarker] = useState(true)
    const [prevColor, setPrevColor] = useState(null)
    const [markerSize, setMarkerSize] = useState(10)
    const [pageId, setPageId] = useState(
        JSON.parse(window.localStorage.getItem("currentPage")).id || null
    )
    const [bookId, setBookId] = useState(null)
    const [imageURL, setImageURL] = useState("")

    const { addToast } = useToasts()

    // const [pages]
    let pagesArray = JSON.parse(window.localStorage.getItem("savedDrawings")) || []
    let stageRef = useRef(null)

    //=============== Local helpers ===============//
    const saveLinesObject = (data) => {
        setLinesObject(data)

        console.log("save drawing to localstorage")
        window.localStorage.setItem("drawingLines", JSON.stringify(data))
    }

    // Init linesObject from local storage
    useEffect(() => {
        console.log("load page")
    }, [])

    //Save drawing progress to localstorage
    useEffect(() => {
        console.log(linesObject, pageId)
        if (linesObject.length > 0) {
            return
        }
        //Check if page id exists within savedArray
        // let savedProgress = pagesArray.findIndex((item) => item.id === pageId)
        // console.log(savedProgress)

        // pagesArray[savedProgress].drawing = null

        // console.log(JSON.parse(JSON.parse(savedProgress[0]?.drawing)))
        //Show saved progress
        // if (savedProgress > -1) {
        //     console.log("update existing page")
        //     // let copyPageArray = [...pagesArray]
        //     // console.log(copyPageArray)
        //     //If page id exists then update
        //     pagesArray[savedProgress].drawing = JSON.stringify(storedDrawing)
        //     window.localStorage.setItem("savedDrawings", JSON.stringify(pagesArray))
        //     // pagesArray.find((item) => item.id === pageId)
        // } else {
        //     //Else add
        //     console.log("add new page")
        //     pagesArray.push({ id: pageId, drawing: JSON.stringify(storedDrawing) })
        //     // console.log(JSON.parse(pagesArray[0].drawing))
        //     window.localStorage.setItem("savedDrawings", JSON.stringify(pagesArray))
        // }
    }, [linesObject])

    // Set marker color state based on props
    useEffect(() => {
        console.log("new marker color", props.markerColor)
        setMarkerColor(props.markerColor)
    }, [props.markerColor])

    // Set marker size state based on props
    useEffect(() => {
        console.log("change marker size", props.markerSize)
        setMarkerSize(props.markerSize)
    }, [props.markerSize])

    // useEffect(() => {
    //     console.log("current page id", props.pageIndex)
    //     setPageId(props.pageIndex)
    // }, [props.pageIndex])

    useEffect(() => {
        setBookId(props.bookId)
    }, [props.bookId])

    useEffect(() => {
        const checkSize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        window.addEventListener("resize", checkSize)
        return () => window.removeEventListener("resize", checkSize)
    }, [])

    useEffect(() => {
        // If there is a saved image or changed image
        if (props.imageSrc?.length > 0) {
            setImageURL(props.imageSrc)
            setMarkerColor(initialColor)

            // Save the new image to local storage
            window.localStorage.setItem("baseImage", props.imageSrc)

            if (imageURL !== "") {
                // Reset the colouring lines
                setLinesObject([])
            }
            setPageId(props.pageIndex)
            console.log("page changed", props.pageIndex, pageId, pagesArray)
            let savedProgress = pagesArray.filter((item) => item.id === props.pageIndex)
            console.log(savedProgress)
            // console.log(JSON.parse(JSON.parse(savedProgress[0]?.drawing)))
            //Show saved progress
            if (savedProgress.length > 0) {
                const storedDrawing = savedProgress[0].drawing
                console.log(JSON.parse(JSON.parse(storedDrawing)))
                if (storedDrawing) {
                    // let drawingInfo = JSON.parse(storedDrawing)
                    // // console.log(storedDrawing, Object.entries(storedDrawing).length)
                    // let currentPainting = [drawingInfo].find(
                    //     (item) => item.paintingPage === pageId
                    // )
                    // console.log(currentPainting)
                    // if (currentPainting) {
                    //     setLinesObject(currentPainting.lines)
                    // }
                    // setLinesObject(drawingInfo.lines)
                    setLinesObject(JSON.parse(JSON.parse(storedDrawing)))
                }
            } else {
                //show unsaved progress
            }

            //Load drawing faster
            // const storedDrawing = window.localStorage.getItem("drawingLines")
            // if (storedDrawing) {
            //     // console.log(
            //     //     "load stored drawing",
            //     //     storedDrawing,
            //     //     Object.entries(storedDrawing).length
            //     // )
            //     // let drawingInfo = JSON.parse(storedDrawing)
            //     // console.log(pageId)
            //     // let currentPainting = [drawingInfo].find(
            //     //     (item) => item.paintingPage === pageId
            //     // )
            //     // console.log(currentPainting)
            //     // if (currentPainting) {
            //     //     setLinesObject(currentPainting.lines)
            //     // }
            //     // if ([drawingInfo].find((item) => item.paintingPage === pageId)) {
            //     //     console.log("found")
            //     //     setLinesObject(drawingInfo.lines)
            //     // }
            //     // console.log(storedDrawing, Object.entries(storedDrawing).length)
            //     // setLinesObject(drawingInfo.lines)
            //     setLinesObject(JSON.parse(storedDrawing))
            // }
        }
    }, [props.imageSrc, props.pageIndex])

    // useEffect(() => {
    //     console.log('drawing line', lines)
    // }, [lines])

    const switchColor = (color) => {
        setMarkerColor(color)
    }

    const handleChangeColor = (color, event) => {
        console.log("handle change color", color, event)
        const hexColor = `${color.rgb.r},${color.rgb.g},${color.rgb.b},${brushOpacity}`
        setMarkerColor(`rgba(${hexColor})`)
        // setHexColor(color.hex)
    }

    const handleMouseDown = () => {
        // this._drawing = true;
        setIsDrawing(true)
        // console.log('begin drawing', lines)
        // console.log(linesObject)
        setLines((prev) => [...prev, []])
        // console.log('update drawing', lines)
    }

    const handleMouseUp = () => {
        console.log(lines)
        // add to lines
        saveLinesObject([
            ...linesObject,
            {
                points: lines[lines.length - 1],
                lineColor: markerColor,
                strokeWidth: parseInt(markerSize),
            },
        ])

        setLines([])
        setIsDrawing(false)
    }

    const handleMouseMove = () => {
        if (isDrawing) {
            const stage = stageRef.getStage()
            const point = stage.getPointerPosition()
            let lastLine = lines[lines.length - 1]
            lastLine = lastLine.concat([parseInt(point.x), parseInt(point.y)])
            lines.splice(lines.length - 1, 1, lastLine)
            // console.log(lines.concat())
            setLines(lines.concat())
        }
    }

    // Download canvas using a temporary link
    function downloadURI(uri, name) {
        const link = document.createElement("a")
        link.download = name
        link.href = uri
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // delete link;
    }

    const handleUndo = () => {
        console.log("handle undo")
        linesObject.splice(-1, 1)
        saveLinesObject([...linesObject])

        addToast({ status: "info", text: "Removed last change" })
    }

    const enableMarker = () => {
        console.log("handle marker")
        if (prevColor) {
            setMarkerColor(prevColor)
        }

        toggleMarker(!isMarker)

        addToast({ status: "info", text: "Now using marker" })
    }

    const enableEraser = () => {
        console.log("handle eraser")
        setPrevColor(markerColor)
        setMarkerColor("rgba(255,255,255, 1)")

        toggleMarker(!isMarker)

        addToast({ status: "info", text: "Now using eraser" })
    }

    const handleSaveCanvas = () => {
        const storedDrawing = window.localStorage.getItem("drawingLines")
        console.log(storedDrawing, pageId)

        if (pagesArray === null) {
            pagesArray = []
        }

        //Check if page id exists within savedArray
        let savedProgress = pagesArray.findIndex((item) => item.id === pageId)
        console.log(savedProgress)
        // console.log(JSON.parse(JSON.parse(savedProgress[0]?.drawing)))
        //Show saved progress
        if (savedProgress > -1) {
            console.log("update existing page")
            // let copyPageArray = [...pagesArray]
            // console.log(copyPageArray)
            //If page id exists then update
            pagesArray[savedProgress].drawing = JSON.stringify(storedDrawing)
            window.localStorage.setItem("savedDrawings", JSON.stringify(pagesArray))

            addToast({ status: "success", text: "Saving artwork...." })
            // pagesArray.find((item) => item.id === pageId)
        } else {
            //Else add
            console.log("add new page")
            pagesArray.push({ id: pageId, drawing: JSON.stringify(storedDrawing) })
            // console.log(JSON.parse(pagesArray[0].drawing))
            window.localStorage.setItem("savedDrawings", JSON.stringify(pagesArray))

            addToast({ status: "success", text: "Saving artwork...." })
        }

        // let pagesArray = []
        // pagesArray.push({ id: pageId, drawing: JSON.stringify(storedDrawing) })
        // console.log(JSON.parse(pagesArray[0].drawing))
        // window.localStorage.setItem("savedDrawings", JSON.stringify(pagesArray))
    }

    const handleDownloadCanvas = () => {
        console.log("handle download canvas")
        const stage2 = stageRef.getStage()
        const dataURL = stage2.toDataURL()

        Swal.fire({
            title: "Do you want to download your art?",
            text: "This will download a copy of the art to your computer",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, download!",
            cancelButtonText: "No, not yet",
        }).then((result) => {
            if (result.value) {
                // TODO: Set the name programmatically or dynamically?
                downloadURI(dataURL, "ncfcolouringbook_artwork.jpg")
                Swal.fire("Canvas downloaded!", "You can keep colouring.", "success")
            }
        })
    }

    const handleClearCanvas = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "This will remove all of your progress!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes!",
            cancelButtonText: "No, keep it",
            confirmButtonColor: "#d33",
        }).then((result) => {
            if (result.value) {
                setIsDrawing(true)
                setLinesObject([])

                let tempPagesArray = [...pagesArray]
                tempPagesArray = tempPagesArray.filter((item) => item.id !== pageId)
                console.log(tempPagesArray)
                window.localStorage.setItem(
                    "savedDrawings",
                    JSON.stringify(tempPagesArray)
                )

                // window.localStorage.setItem('savedDrawing')

                Swal.fire(
                    "Canvas Cleared!",
                    "You now have a fresh start.",
                    "success"
                ).then((res) => setIsDrawing(false))
            }
        })
    }

    const handleStrokeWidth = (operand) => {
        let currentMarkerSize = markerSize
        if (operand === "add") {
            currentMarkerSize++
        } else {
            currentMarkerSize--
        }
        setMarkerSize(currentMarkerSize)
        // setMarkerSize(evt.target.value)
    }

    const handleFullView = () => {
        setIsFullView(!isFullView)
    }

    // status can be "loading", "loaded" or "failed"
    const [sourceImage, status] = useImage(imageURL, "Anonymous")

    var SCENE_BASE_WIDTH = 800
    var SCENE_BASE_HEIGHT = 700

    // do your calculations for stage properties
    const screenScale = (size.width * 0.8) / SCENE_BASE_WIDTH

    // const canvasWidth = size.width * 0.8
    const canvasWidth =
        screenScale > 1 ? SCENE_BASE_WIDTH : SCENE_BASE_WIDTH * screenScale
    const canvasHeight =
        screenScale > 1 ? SCENE_BASE_HEIGHT : SCENE_BASE_HEIGHT * screenScale

    // const loadCanvas = (…)=> {
    //     return condition1 ? value1
    //         : condition2 ? value2
    //             : condition3 ? value3
    //                 : value4;
    // }

    return (
        <div
            className={`${
                !isFullView ? "container" : "w-full"
            } flex flex-col items-center justify-center min-h-full md:m-10 bg-white rounded-lg shadow-lg p-3`}
            style={
                isFullView
                    ? {
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 100,
                      }
                    : {}
            }
        >
            {/* {status} */}
            {/* <div>{JSON.stringify(size)}</div>
            <div>{JSON.stringify({ width: stageWidth, height: stageHeight })}</div>
            <div>{screenScale}</div> */}
            <TransformWrapper
                defaultScale={1}
                defaultPositionX={0}
                defaultPositionY={0}
                pinch={{ disabled: true }}
                pan={{ disabled: true }}
                options={{ minScale: 1, maxScale: 15, limitToBounds: false }}
            >
                {({ scale, zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                        <div className="w-full mb-10 flex items-center justify-between">
                            <div className="grid grid-cols-1 md:grid-cols-3">
                                <Link
                                    className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white focus:outline-none"
                                    to="/home"
                                >
                                    <i className="fas fa-book-open fa-lg" />
                                </Link>
                                <button
                                    type="button"
                                    className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white focus:outline-none"
                                    onClick={handleUndo}
                                >
                                    <i className="fas fa-undo-alt fa-lg" />
                                </button>
                                {isMarker ? (
                                    <button
                                        type="button"
                                        className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white focus:outline-none"
                                        onClick={enableEraser}
                                    >
                                        <i className="fas fa-eraser fa-lg" />
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white focus:outline-none"
                                        onClick={enableMarker}
                                    >
                                        <i className="fas fa-paint-brush fa-lg" />
                                    </button>
                                )}
                            </div>

                            <div className="w-3/4 flex flex-wrap justify-center bg-gray-100 rounded p-2">
                                {arr_colours.map((colour, index) => (
                                    <div
                                        key={index}
                                        className="h-8 w-8 bg-gray-400 rounded-full border-2 border-white hover:border-gray-600"
                                        style={{ backgroundColor: `${colour}` }}
                                        onClick={() => switchColor(colour)}
                                    ></div>
                                ))}
                            </div>
                            <div className="grid grid-cols-2 md:grid-cols-3">
                                <Popover
                                    open={showZoomControls}
                                    onClose={() => toggleZoomControls(!showZoomControls)}
                                >
                                    Change zoom level
                                    <div className="flex items-center justify-center">
                                        <button
                                            type="button"
                                            className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white"
                                            onClick={zoomIn}
                                        >
                                            <i className="fas fa-search-plus fa-lg" />
                                        </button>
                                        <div className="flex-1 px-2">
                                            {parseInt(scale * 100)}
                                        </div>
                                        <button
                                            type="button"
                                            className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white"
                                            onClick={zoomOut}
                                        >
                                            <i className="fas fa-search-minus fa-lg" />
                                        </button>
                                    </div>
                                </Popover>
                                <button
                                    type="button"
                                    title="Change zoom level"
                                    className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white"
                                    onClick={() => toggleZoomControls(!showZoomControls)}
                                >
                                    <i className="fas fa-search fa-lg" />
                                </button>
                                <Popover
                                    open={showBrushControls}
                                    onClose={() =>
                                        toggleBrushControls(!showBrushControls)
                                    }
                                >
                                    Change brush size
                                    <div className="flex items-center justify-center">
                                        <button
                                            type="button"
                                            className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white"
                                            onClick={() => handleStrokeWidth("add")}
                                        >
                                            <i className="fas fa-chevron-up fa-lg" />
                                        </button>
                                        <div className="flex-1 px-2">{markerSize}</div>
                                        <button
                                            type="button"
                                            className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white"
                                            onClick={() => handleStrokeWidth("minus")}
                                        >
                                            <i className="fas fa-chevron-down fa-lg" />
                                        </button>
                                    </div>
                                </Popover>
                                <button
                                    type="button"
                                    title="Change brush size"
                                    className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white"
                                    onClick={() =>
                                        toggleBrushControls(!showBrushControls)
                                    }
                                >
                                    <i className="fas fa-plus fa-lg" />
                                </button>
                                <ColorPicker
                                    labelText="Colours"
                                    color={rgba2hex(markerColor)}
                                    onChange={handleChangeColor}
                                />
                                <button
                                    type="button"
                                    className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white"
                                    onClick={handleSaveCanvas}
                                >
                                    <i className="fas fa-save fa-lg" />
                                </button>
                                <button
                                    type="button"
                                    className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400  hover:text-white"
                                    onClick={handleDownloadCanvas}
                                >
                                    <i className="fas fa-file-download fa-lg" />
                                </button>
                                <button
                                    type="button"
                                    className="flex flex-col items-center p-3 border border-gray-400 rounded text-gray-600 hover:bg-blue-400 hover:text-white"
                                    onClick={handleClearCanvas}
                                >
                                    <i className="fas fa-trash fa-lg" />
                                </button>
                            </div>
                        </div>
                        <TransformComponent>
                            <Stage
                                className="flex p-3 text-center items-center justify-center mx-auto border border-gray-600 rounded shadow-lg cursor-pointer"
                                width={canvasWidth}
                                height={canvasHeight}
                                // width={stageWidth}
                                // height={stageHeight}
                                // width={SCENE_BASE_WIDTH}
                                // height={SCENE_BASE_HEIGHT}
                                // scaleX={screenScale}
                                // scaleY={screenScale}
                                onContentTouchstart={handleMouseDown}
                                onContentTouchmove={handleMouseMove}
                                onContentTouchend={handleMouseUp}
                                onContentMousedown={handleMouseDown}
                                onContentMousemove={handleMouseMove}
                                onContentMouseup={handleMouseUp}
                                ref={(node) => {
                                    stageRef = node
                                }}
                            >
                                <Layer>
                                    {linesObject.map((line, i) => (
                                        <Line
                                            x={0}
                                            y={0}
                                            key={guid()}
                                            visible={true}
                                            points={line.points}
                                            lineCap="round"
                                            lineJoin="round"
                                            stroke={
                                                !line.lineColor
                                                    ? markerColor
                                                    : line.lineColor
                                            }
                                            strokeWidth={
                                                !line.strokeWidth
                                                    ? markerSize
                                                    : line.strokeWidth
                                            }
                                        />
                                    ))}
                                </Layer>
                                <Layer>
                                    {lines.map((line, i) => {
                                        return (
                                            <Line
                                                x={0}
                                                y={0}
                                                key={guid()}
                                                visible={true}
                                                points={line}
                                                lineCap="round"
                                                lineJoin="round"
                                                stroke={markerColor}
                                                strokeWidth={markerSize}
                                            />
                                        )
                                    })}
                                </Layer>
                                <Layer>
                                    {status === "loading" ? (
                                        <Text text="Loading image. Please wait...." />
                                    ) : status === "failed" ? (
                                        <Text text="Something went wrong. Please refresh" />
                                    ) : (
                                        <Image
                                            image={sourceImage}
                                            width={canvasWidth}
                                            height={canvasHeight}
                                        />
                                    )}
                                </Layer>
                            </Stage>
                        </TransformComponent>
                    </React.Fragment>
                )}
            </TransformWrapper>
        </div>
    )
}

export default DrawingCanvas

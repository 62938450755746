import React from "react"
import { Link } from "react-router-dom"

const Page404 = () => (
    <div className="flex flex-col content-center justify-center items-center h-screen">
        <h1 className="text-5xl uppercase font-bold text-gray-400">404</h1>
        <h2 className="text-xl py-3 text-gray-600">
            Sorry but that page is not available
        </h2>
        <Link to="/" className="bg-blue-500 py-2 px-4 rounded text-white">
            Back to Homepage
        </Link>
    </div>
)

export default Page404

import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import Canvas from "../components/Canvas"

const Page = () => {
    const [markerColor] = useState("rgba(233,30,99,1)")
    const [markerSize] = useState(30)

    const [selectedBook, setSelectedBook] = useState(null)
    const [selectedPage, setPage] = useState("")
    const [baseImage, setBaseImage] = useState("")

    let { page, book } = useLocation()

    useEffect(() => {
        setSelectedBook(book)
        setPage(page)
    }, [page, book])

    useEffect(() => {
        // Display intro modal if there's no drawing
        const storedDrawing = window.localStorage.getItem("baseImage")
        // console.log(storedDrawing, storedDrawing?.length)
        if (storedDrawing) {
            setBaseImage(storedDrawing)
        }

        const savedPage = window.localStorage.getItem("currentPage")
        // console.log("selected page", savedPage)
        if (savedPage) {
            setPage(JSON.parse(savedPage))
        }

        const savedBook = window.localStorage.getItem("currentBook")
        // console.log("selected book", savedBook)
        if (savedBook) {
            let bookData = JSON.parse(savedBook)
            setSelectedBook(bookData)
            // fetchPages(bookData.id)
        }

        // fetchData()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="flex justify-center items-center">
            {selectedBook && selectedPage && (
                <Canvas
                    markerColor={markerColor}
                    markerSize={markerSize}
                    bookId={selectedBook.id}
                    pageIndex={selectedPage.id}
                    imageSrc={selectedPage.image}
                />
            )}
        </div>
    )
}

export default Page

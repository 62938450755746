import React, { useState, useContext } from "react"
import { withRouter, Redirect } from "react-router"
import { Link } from "react-router-dom"
import * as yup from "yup"
import useYup from "@usereact/use-yup"
// import firebase from '../config/firebase';
import { AuthContext } from "../context/Auth"

import NCFLogo from "../assets/img/ncf_logo.png"

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
    password: yup
        .string()
        .min(6)
        .required(),
})

const Login = ({ history }) => {
    const [values, setValues] = useState({
        email: "",
        password: "",
    })
    const [formError, setFormError] = useState("")

    const { errors, validate } = useYup(values, validationSchema, {
        validateOnChange: false,
    })

    const { currentUser, handleLogin } = useContext(AuthContext)

    if (currentUser) {
        return <Redirect to="/home" />
    }
    // console.log('errors: ', errors)
    const handleChange = (e) => {
        const { name, value } = e.target

        validate()
        setValues({
            ...values,
            [name]: value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const { email, password } = values

        try {
            const result = await handleLogin({ email, password })
            console.log(result)
            history.push("/admin")
        } catch (error) {
            // alert(error);
            console.error(error)
            setFormError(
                "Sorry but we do not recognize that email/password combination. Please try again"
            )
        }
    }

    return (
        <div className="container mx-auto">
            <div className="flex justify-center items-center px-6 my-12">
                <div className="w-full xl:w-3/4 lg:w-11/12 bg-white flex justify-center items-center shadow-lg">
                    <div className="w-full h-full bg-white flex py-3 pb-10 hidden lg:block lg:w-1/2 rounded-l-lg">
                        <img
                            src={NCFLogo}
                            className="object-cover object-center block mx-auto"
                            alt="ncf logo"
                        />
                    </div>

                    <div className="w-full flex flex-col justify-center lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
                        {formError && (
                            <div
                                className="w-full bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-6 rounded relative"
                                role="alert"
                            >
                                <strong className="font-bold">Error!</strong>
                                <span className="block sm:inline"> {formError}</span>
                            </div>
                        )}
                        <h3 className="pt-4 text-2xl text-center">Admin Console</h3>
                        <form
                            className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
                            onSubmit={handleSubmit}
                        >
                            <div className="mb-4">
                                <label
                                    className="block mb-2 text-sm font-bold text-gray-700"
                                    htmlFor="email"
                                >
                                    Email
                                </label>
                                <input
                                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="text"
                                    name="email"
                                    placeholder="Enter your email address"
                                    value={values.email}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors.email && (
                                    <p className="text-red-500 text-xs italic">
                                        {errors.email}
                                    </p>
                                )}
                            </div>
                            <div className="mb-4">
                                <label
                                    className="block mb-2 text-sm font-bold text-gray-700"
                                    htmlFor="password"
                                >
                                    Password
                                </label>
                                <input
                                    name="password"
                                    value={values.password}
                                    onChange={(e) => handleChange(e)}
                                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    id="password"
                                    type="password"
                                    placeholder="******************"
                                />
                                {errors.password && (
                                    <p className="text-red-500 text-xs italic">
                                        {errors.password}
                                    </p>
                                )}
                            </div>

                            <div className="mb-6 text-center">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                    type="submit"
                                >
                                    Sign In
                                </button>
                            </div>
                            <hr className="mb-6 border-t" />

                            <div className="text-center">
                                <Link
                                    className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                                    to="/forgotpassword"
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Login)

import React, { useContext } from "react"
import { Link } from "react-router-dom"
// import firebase from '../config/firebase';
import { AuthContext } from "../context/Auth"

import NCFLogo from "../assets/img/ncf_logo.png"

const Navbar = () => {
    const { currentUser, handleLogout } = useContext(AuthContext)
    // console.log(currentUser)
    return (
        <nav
            className="inset-x-0 top-0 z-10 w-full py-4 bg-white text-gray-800 xl:py-4 shadow-md"
            // style={{ zIndex: '999' }}
            id="navbar"
        >
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between">
                    <Link className="flex-1" to="/">
                        <div className="flex items-center justify-start flex-shrink-0 text-gray-800 mr-16">
                            <img
                                src={NCFLogo}
                                className="object-cover object-center block"
                                style={{ height: "50px" }}
                                alt="ncf logo"
                            />
                        </div>
                    </Link>

                    <nav className="flex-1 flex justify-end items-center">
                        <Link to="/" className="font-medium  text-gray-600 text-md mr-3">
                            Home
                        </Link>
                        {/* <Link
              to="/statistics"
              className="font-medium text-white hover:text-gray-600 text-sm mr-3"
            >
              Statistics
          </Link>
            <Link
              to="/dashboard"
              className="font-medium text-white hover:text-gray-600 text-sm mr-3"
            >
              Dashboard
          </Link> */}
                        {currentUser && (
                            <Link
                                to="/admin"
                                className="font-medium text-gray-600 text-md mr-3"
                            >
                                Admin
                            </Link>
                        )}
                        {!currentUser ? (
                            <Link
                                to="/login"
                                className="font-medium text-gray-600 text-md mr-3"
                            >
                                Login
                            </Link>
                        ) : (
                            <button
                                type="button"
                                onClick={() => handleLogout()}
                                className="block px-4 py-2 mt-3 font-medium text-white bg-blue-500 rounded hover:bg-blue-700 md:ml-6 md:mt-0 text-md leading-tight"
                            >
                                Sign Out
                            </button>
                        )}
                    </nav>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
